.errorContainer {
  height: 87vh;
  /* background: url('/static/images/404.png') no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.root {
  padding: 30px 0 30px 20px;
  width: 70%;
  margin: 0 auto;
}

.header {
  font-size: 80px;
  font-weight: bold;
}

.homeButton {
  margin-top: 10px;
}
