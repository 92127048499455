.sider {
  min-height: 100vh;
  /* box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35); */
  position: relative;
  z-index: 10;
  background: #fff;
}

.menu {
  padding: 16px 0;
  width: 100%;
}

:global {
  .drawer .drawer-content {
    background: var(--primary);
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    box-shadow: none;
  }
  .ant-menu-inline-collapsed {
    & > .ant-menu-item .sider-menu-item-img + span,
    & > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
    & > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
      max-width: 0;
      display: inline-block;
      opacity: 0;
    }
  }
  .ant-menu-item .sider-menu-item-img + span,
  .ant-menu-submenu-title .sider-menu-item-img + span {
    transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), width 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    opacity: 1;
    font-size: 20px;
  }
}
