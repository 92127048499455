.main {
  width: 368px;
  margin: 0 auto;
  padding-top: 40px;
}

.icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}

.login {
  & :global(.ant-form-item) {
    margin-bottom: 24px;
  }

  & .submit {
    width: 100%;
    margin-top: 24px;
  }

  & .login-form {
    max-width: 300px;
  }
}
